exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-career-thank-you-js": () => import("./../../../src/pages/career-thank-you.js" /* webpackChunkName: "component---src-pages-career-thank-you-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-enquiry-thank-you-js": () => import("./../../../src/pages/enquiry-thank-you.js" /* webpackChunkName: "component---src-pages-enquiry-thank-you-js" */),
  "component---src-pages-hulsta-dubai-js": () => import("./../../../src/pages/hulsta-dubai.js" /* webpackChunkName: "component---src-pages-hulsta-dubai-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-hulsta-thank-you-js": () => import("./../../../src/pages/lp-hulsta-thank-you.js" /* webpackChunkName: "component---src-pages-lp-hulsta-thank-you-js" */),
  "component---src-pages-lp-thank-you-js": () => import("./../../../src/pages/lp-thank-you.js" /* webpackChunkName: "component---src-pages-lp-thank-you-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-partner-thank-you-js": () => import("./../../../src/pages/partner-thank-you.js" /* webpackChunkName: "component---src-pages-partner-thank-you-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-project-division-js": () => import("./../../../src/pages/project-division.js" /* webpackChunkName: "component---src-pages-project-division-js" */),
  "component---src-pages-rolf-benz-dubai-js": () => import("./../../../src/pages/rolf-benz-dubai.js" /* webpackChunkName: "component---src-pages-rolf-benz-dubai-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-subscription-thank-you-js": () => import("./../../../src/pages/subscription-thank-you.js" /* webpackChunkName: "component---src-pages-subscription-thank-you-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/BlogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-detail-page-js": () => import("./../../../src/templates/BlogDetailPage.js" /* webpackChunkName: "component---src-templates-blog-detail-page-js" */),
  "component---src-templates-identity-js": () => import("./../../../src/templates/Identity.js" /* webpackChunkName: "component---src-templates-identity-js" */),
  "component---src-templates-room-types-js": () => import("./../../../src/templates/RoomTypes.js" /* webpackChunkName: "component---src-templates-room-types-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/Footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/Header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

